import * as React from 'react'
import { Page } from '../components/page'
import { graphql, PageProps } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { Teaser } from '../components/teaser'
import { Content } from '../components/content'
import ReactMarkdown from 'react-markdown'
import { Seo } from '../components/seo'

const PrivacyPage = ({ data }: PageProps<Queries.PrivacyPageQuery>) => {
    const { t }: { t: any } = useTranslation()
    return (
        <Page pageName={'PRIVACY'}>
            <Seo title={t('PAGES.PRIVACY.HEADER')} />
            <Teaser pageName={'PRIVACY'} image={'/images/teasers/shawn-xiao-sc_hcc9i-0E-unsplash.jpg'}></Teaser>
            <Content>
                <h2>{t('PAGES.PRIVACY.GDPR_CONTACT_HEADER')}</h2>
                <p>
                    {data.site?.siteMetadata?.contact?.name}
                    <br />
                    <br />
                    {data.site?.siteMetadata?.contact?.street}
                    <br />
                    {data.site?.siteMetadata?.contact?.zip}{' '}
                    {data.site?.siteMetadata?.contact?.city}
                    <br />
                    <br />
                    {data.site?.siteMetadata?.contact?.email}
                </p>
                <ReactMarkdown children={t('PAGES.PRIVACY.COOKIES')} />
                <ReactMarkdown children={t('PAGES.PRIVACY.LOGS')} />
                <ReactMarkdown children={t('PAGES.PRIVACY.RIGHTS')} />
            </Content>
        </Page>
    )
}

export default PrivacyPage

export const query = graphql`
    query PrivacyPage($language: String!) {
        site {
            siteMetadata {
                title
                contact {
                    name
                    street
                    zip
                    city
                    email
                }
            }
        }
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
